<template>
  <el-container :loading="restrictDetailsLoading">
    <el-header class="header1" height="32">
      <el-row type="flex" justify="start">
        <div class="back">
          <Icon iconName="arrow_left_white" />
          <Icon iconName="letter_a_white" />
        </div>
        <BreadCrumbs />
        <UserProfileButton /> </el-row
    ></el-header>
    <el-header class="header2" height="32"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="createNew" :title="titles.titleAddItems[activeTab]" />
        <TIButton
          icon="edit_yellow"
          @onClick="editSelected"
          :disabled="!selected[activeTab] || selected[activeTab].length != 1"
          :title="titles.titleEditItems[activeTab]"
        />
        <TIButton
          icon="delete_red"
          @onClick="deleteSelected"
          :disabled="!selected[activeTab] || selected[activeTab].length == 0"
          :title="titles.titleDelItems[activeTab]"
        />
        <TSeparator />
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="import-from-xlsx" :disabled="activeTab != 'sku'"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-from-json" :disabled="activeTab != 'sku'"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-from-groups" :disabled="activeTab != 'sku'"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із конкурентних груп") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-org-w-plan-from-xlsx" :disabled="activeTab != 'orgs'"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("Структури і план із Excel (.xlsx)") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="export_blue" :label="$t('Експорт')" dropdown="true" :disabled="!selected[activeTab]" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export-to-json" :disabled="!selected[activeTab]"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("в JSON") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <TSeparator />
        <el-dropdown trigger="click" @command="handleClick" v-if="activeTab == 'orgs'">
          <span class="el-dropdown-link">
            <TIButton icon="plus_green" :label="$t('Планування')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-tickets" command="calc-plan" :disabled="activeTab != 'orgs'">{{ $t("Розподілити план") }}</el-dropdown-item>
            <el-dropdown-item command="clear-plan" :disabled="activeTab != 'orgs'"
              ><img class="svg" :src="require('../assets/icons/delete_red.svg')" />{{ $t("Зкинути план") }}</el-dropdown-item
            >
            <el-dropdown-item command="calc-plan-settings" :disabled="activeTab != 'orgs'" divided
              ><img class="svg" :src="require('../assets/icons/settings_yellow.svg')" />{{ $t("Налаштування плану...") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <TSeparator v-if="activeTab == 'orgs'" />
      </el-row>
    </el-header>
    <el-header class="header3 tab-bar" height="48">
      <el-tabs v-model="activeTab">
        <el-tab-pane :label="$t('План/Бонус {count}', { count: '(' + rulesComp.length + ')' })" name="rules"></el-tab-pane>
        <el-tab-pane :label="$t('Структури {count}', { count: '(' + orgsComp.length + ')' })" name="orgs"></el-tab-pane>
        <el-tab-pane :label="$t('Товари {count}', { count: '(' + skuComp.length + ')' })" name="sku"></el-tab-pane>
      </el-tabs>
    </el-header>
    <el-header class="header3" height="48"
      ><el-row type="flex" justify="start">
        <div style="padding-left:19px;">
          <el-input
            :placeholder="$t('Пошук')"
            style="width:308px;"
            prefix-icon="el-icon-search"
            clearable
            v-model="filterByName"
            ref="inputFilterName"
            @keydown.esc.native="resetFilterName"
          />
        </div> </el-row
    ></el-header>
    <el-main id="mainGridContainer"
      ><ag-grid-vue
        v-show="activeTab == 'rules'"
        ref="restrictDetailsPlanBonus"
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        :defaultColDef="defaultColDef"
        :columnDefs="columns.rules"
        :headerHeight="32"
        :context="context"
        :rowData="rulesComp"
        :modules="modules"
        rowSelection="multiple"
        :gridOptions="gridOptions1"
        :frameworkComponents="frameworkComponents"
        :enableBrowserTooltips="true"
        @selection-changed="onGridSelectionChangedRules"
        @rowDoubleClicked="onGridDoubleClick"
        @sortChanged="onGridColumnsChange1"
        @columnResized="onGridColumnsChange1"
        @columnMoved="onGridColumnsChange1"
        @displayedColumnsChanged="onGridColumnsChange1"
        :overlayNoRowsTemplate="noRowsToShowTemplate"
        :sideBar="sideBar"
        :localeTextFunc="gridLocale"
      >
      </ag-grid-vue>
      <div ref="flex_container" v-show="activeTab == 'orgs'">
        <splitpanes class="default-theme" horizontal>
          <pane min-size="20" max-size="80">
            <ag-grid-vue
              ref="restrictDetailsOrgs"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              treeData="true"
              :getDataPath="getDataPath"
              :autoGroupColumnDef="autoGroupColumnDef"
              :defaultColDef="defaultColDef"
              :columnDefs="columns.orgs"
              :headerHeight="32"
              :context="context"
              :rowData="orgsCompOKPO"
              :modules="modules"
              rowSelection="single"
              :gridOptions="gridOptions2"
              :groupDefaultExpanded="-1"
              :frameworkComponents="frameworkComponents"
              :enableBrowserTooltips="true"
              @selection-changed="onGridSelectionChangedOrgs"
              @rowDoubleClicked="onGridDoubleClickOrg"
              @sortChanged="onGridColumnsChange2"
              @columnResized="onGridColumnsChange2"
              @columnMoved="onGridColumnsChange2"
              @displayedColumnsChanged="onGridColumnsChange2"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              :sideBar="sideBar"
              :localeTextFunc="gridLocale"
              :pinnedBottomRowData="orgsTotalComp"
              @cell-value-changed="onGrid2CellValueChanged"
            >
            </ag-grid-vue>
          </pane>
          <pane min-size="20" max-size="80">
            <ag-grid-vue
              ref="restrictDetailsOrgsChild"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              treeData="false"
              :getDataPath="getDataPath"
              :defaultColDef="defaultColDef"
              :columnDefs="columns.orgsChild"
              :headerHeight="32"
              :context="context"
              :rowData="orgsChildComp"
              :modules="modules"
              rowSelection="multiple"
              :gridOptions="gridOptions2Child"
              :frameworkComponents="frameworkComponents"
              :enableBrowserTooltips="true"
              @selection-changed="onGridSelectionChangedOrgsChild"
              @rowDoubleClicked="onGridDoubleClick"
              @sortChanged="onGridColumnsChange2Child"
              @columnResized="onGridColumnsChange2Child"
              @columnMoved="onGridColumnsChange2Child"
              @displayedColumnsChanged="onGridColumnsChange2"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              :sideBar="sideBar"
              :localeTextFunc="gridLocale"
            >
            </ag-grid-vue>
          </pane>
        </splitpanes>
      </div>

      <ag-grid-vue
        v-show="activeTab == 'sku'"
        ref="restrictDetailsSKU"
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        :defaultColDef="defaultColDef"
        :columnDefs="columns.sku"
        :headerHeight="32"
        :context="context"
        :rowData="skuComp"
        rowSelection="multiple"
        :gridOptions="gridOptions3"
        :frameworkComponents="frameworkComponents"
        :enableBrowserTooltips="true"
        :modules="modules"
        @selection-changed="onGridSelectionChangedSKU"
        @rowDoubleClicked="onGridDoubleClickSKU"
        @sortChanged="onGridColumnsChange3"
        @columnResized="onGridColumnsChange3"
        @columnMoved="onGridColumnsChange3"
        @displayedColumnsChanged="onGridColumnsChange3"
        :overlayNoRowsTemplate="noRowsToShowTemplate"
        :sideBar="sideBar"
        :localeTextFunc="gridLocale"
      >
      </ag-grid-vue>
    </el-main>
    <FindOrgInTree @find-commit="onFindOrgsCommit" />
    <EditRestrictRule @commit="restrictRuleCommitChanges" />
    <ImportSKUFromGM @find-commit="onFindSKUCommit" bus-event="forms.sku.import.from.groups" />
    <EditRestrictOrg @commit="restrictOrgsCommitChanges" />
    <EditRestrictSKU @commit="restrictSKUCommitChanges" />
    <EditRestrictPlanSettings @commit="restrictPlanCommitChanges" />
    <SKUInfo bus-event="restricts.details.sku.forms.info" />
    <SKUDictionary @find-commit="onFindSKUCommit" />
    <SelectFile @commit="importSKUFromJSONData" bus-event="forms.sku.import.from.json.file" />
    <SelectFileForImportSKUFromXLS @commit="importSKUFromXLSXData" bus-event="forms.sku.import.from.xlsx.file" />
    <SelectFileForImportOrgWithPlanFromXLS @commit="importOrgWithPlansFromXLSXData" bus-event="forms.org.w.plan.import.from.xlsx.file" />
  </el-container>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import { bus } from "@/main";

import GridCellCheckbox from "@/components/GridCellCheckbox.js";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
import GridTotalRow from "@/components/GridTotalRow.js";
import BreadCrumbs from "@/components/BreadCrumbs";
import UserProfileButton from "@/components/UserProfileButton";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { _ } from "vue-underscore";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

const Restricts = {
  name: "RestrictDetails",
  components: {
    AgGridVue,
    TIButton,
    TSeparator,
    Icon,
    BreadCrumbs,
    UserProfileButton,
    Splitpanes,
    Pane,

    ImportSKUFromGM: () => import("@/components/forms/ImportSKUFromGM"),
    SKUInfo: () => import("@/components/forms/SKUInfo"),
    EditRestrictPlanSettings: () => import("@/components/forms/restricts/EditRestrictPlanSettings"),
    EditRestrictRule: () => import("@/components/forms/restricts/EditRestrictRule"),
    EditRestrictOrg: () => import("@/components/forms/restricts/EditRestrictOrg"),
    EditRestrictSKU: () => import("@/components/forms/restricts/EditRestrictSKU"),
    SKUDictionary: () => import("@/components/forms/SKUDictionary"),
    FindOrgInTree: () => import("@/components/forms/FindOrgInTree"),
    SelectFile: () => import("@/components/forms/SelectFile"),
    SelectFileForImportSKUFromXLS: () => import("@/components/forms/SelectFileForImportSKUFromXLS"),
    SelectFileForImportOrgWithPlanFromXLS: () => import("@/components/forms/SelectFileForImportOrgWithPlanFromXLS"),
  },
  beforeMount() {
    //this.gridOptions = {};
    this.frameworkComponents = { GridCellWithIcons, GridTotalRow, GridCellCheckbox };
    this.context = { componentParent: this };
  },
  mounted() {
    var state = this.$store.getters["main/userSettings"]("restrictDetailsRulesGridColumnState");
    if (state) this.gridOptions1.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsOrgsGridColumnState");
    if (state) this.gridOptions2.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsOrgsChildGridColumnState");
    if (state) this.gridOptions2Child.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSKUGridColumnState");
    if (state) this.gridOptions3.columnApi.setColumnState(state);
    this.activeTab = this.$store.getters["main/userSettings"]("restrictDetailsActiveTab") || "rules";
    this.observeHeight();
    this.checkGridSettingsToChanges(this, $tt("План/бонус"), "restrictDetailsRulesGridColumnState", this.columns["rules"]);
    this.checkGridSettingsToChanges(this, $tt("Товари"), "restrictDetailsSKUGridColumnState", this.columns["sku"]);
    this.checkGridSettingsToChanges(this, $tt("Структури"), "restrictDetailsOrgsGridColumnState", this.columns["orgs"]);
    this.checkGridSettingsToChanges(this, $tt("Структури(аптеки)"), "restrictDetailsOrgsChildGridColumnState", this.columns["orgsChild"]);
  },
  data() {
    var that = this;
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    var digitalValueValidateRule = (value) => {
      if (value == undefined || value == "") {
        return true;
      } else if ((!isNumeric(value) && !isNumeric(value.split(",").join("."))) || value < 0) {
        this.$message.error($tt("Поле має бути невід'ємним числом"));
        return false;
      } else {
        return true;
      }
    };
    var checkEditFunction = function(params) {
      return !params.node.isRowPinned();
    };
    var actionColumn = {
      width: "120px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      resizable: false,
      sortable: false,
      filter: false,
      cellRenderer: "GridCellWithIcons",
      cellIcons: [
        {
          icon: "edit",
          iconClass: "icon-action",
          type: "fas el-icon-fa-",
          color: "green",
          title: $tt("Редагувати"),
          onClick: (params) => {
            this.editSelected(null, params.data);
          },
        },
        {
          icon: "trash",
          iconClass: "icon-action",
          type: "fas el-icon-fa-",
          color: "red",
          title: $tt("Видалити"),
          onClick: (params) => {
            this.deleteSelected(null, params.data);
          },
        },
        {
          icon: "info-circle",
          type: "fas el-icon-fa-",
          color: "blue",
          iconClass: "icon-action",
          title: $tt("Інформація"),
          onClick: (params) => {
            this.showInfo(null, params.data);
          },
        },
      ],
    };
    return {
      selected: {
        rules: null,
        orgs: null,
        orgsChild: null,
        sku: null,
      },
      getDataPath: (data) => {
        return data.path;
      },
      restrictDetailsLoading: false,
      activeTab: "rules",
      gridApiPlanBonus: null,
      gridApiOrgs: null,
      gridApiSKU: null,
      frameworkComponents: null,
      context: null,
      noRowsToShowTemplate: `<div><img src="${require("../assets/icons/no_data.svg")}"></div>`,
      titles: {
        titleAddItems: {
          rules: $tt("Створити новий"),
          orgs: $tt("Додати структуру"),
          sku: $tt("Додати товар"),
        },
        titleEditItems: {
          rules: $tt("Редагувати"),
          orgs: $tt("Редагувати"),
          sku: $tt("Редагувати"),
        },
        titleDelItems: {
          rules: $tt("Видалити"),
          orgs: $tt("Видалити"),
          sku: $tt("Видалити"),
        },
      },
      gridOptions1: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptions2: {
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
        stopEditingWhenGridLosesFocus: true,
      },
      gridOptions2Child: {
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
        stopEditingWhenGridLosesFocus: true,
      },

      gridOptions3: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      windowHeight: 0,
      resizeObserver: null,
      modules: AllModules,
      filterByName: "",
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      bottomTotalRow: {
        orgs: null,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        filter: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        // comparator: function(valueA, valueB) {
        //   return valueA > valueB ? 1 : -1;

        // },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
          if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      columns: {
        rules: [
          actionColumn,
          {
            headerName: $tt("План"),
            field: "plan",
            width: "160px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Бонус"),
            field: "bonus",
            width: "160px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Правило обчислення"),
            field: "applyCondition",
            width: "200px",
            valueGetter: function(params) {
              return { and: $tt("Усі батьківські правила виконуються"), or: $tt("Хоча б одне батьківське правило виконується") }[params.data.applyCondition];
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Батьківські правила"),
            field: "parentRules",
            width: "240px",
            valueGetter: function(params) {
              return params.data.parentRules.length ? $tt("Обрано правил: {count}", { count: params.data.parentRules.length }) : "";
            },
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 1,
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        orgs: [
          actionColumn,
          {
            headerName: $tt("Адреса"),
            field: "address",
            width: "200px",
          },
          {
            headerName: "AxiomaID",
            field: "axiomaId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("ЄДРПОУ"),
            field: "okpo",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("План, од. вимірювання"),
            width: "100px",
            valueGetter: () => {
              return $tt(
                that.selectOptions.restricts.planMeasure.find((option) => option.value == that.$store.getters["restricts/restrict"]().planMeasure).label
              );
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("План, значення"),
            field: "plan",
            width: "100px",
            pinnedRowCellRenderer: "GridTotalRow",
            pinnedRowCellRendererParams: { name: $tt("План") },
            editable: checkEditFunction.bind(this),
            type: "rightAligned",
            singleClickEdit: true,
            valueSetter: function(params) {
              if (digitalValueValidateRule(params.newValue)) {
                that
                  .restrictOrgsCommitChanges({
                    operation: "update",
                    model: _.extend(params.data, { plan: params.newValue, planFixed: isNumeric(params.newValue) && params.newValue >= 0 }),
                  })
                  .then(() => {
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      columns: [params.column],
                    });
                  })
                  .catch(() => {});
              }
              return false;
            },
          },
          {
            headerName: $tt("Фікс. план"),
            field: "planFixed",
            width: "100px",
            cellRenderer: "GridCellCheckbox",
            cellCheckbox: {
              onChange(params, value) {
                if (!params.node.isRowPinned()) that.restrictOrgsCommitChanges({ operation: "update", model: _.extend(params.data, { planFixed: value }) });
              },
            },
            tooltipValueGetter: function(params) {
              if (params.value) return $tt("План фіксовано");
              else return $tt("План не фіксовано");
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Планограма"),
            field: "planogram",
            width: "100px",
            cellRenderer: "GridCellCheckbox",
            cellCheckbox: {
              onChange(params, value) {
                if (!params.node.isRowPinned())
                  that.restrictOrgsCommitChanges({ operation: "update", model: _.extend(params.data, { planogram: value }) }, true);
              },
            },
            tooltipValueGetter: function(params) {
              if (params.value) return $tt("Присутня планограма");
              else return $tt("Відсутня планограма");
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Бонус, од. вимірювання"),
            field: "bonusMeasure",
            width: "100px",
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Умова"),
            field: "calcType",
            width: "200px",
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Тип умови"),
            field: "conditionType",
            width: "120px",
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 1,
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.status ? $tt("Активний") : "-";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Категорія"),
            field: "srcCategory",
            width: "50px",
            filter: "agSetColumnFilter",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        orgsChild: [
          actionColumn,
          {
            headerName: $tt("Назва"),
            field: "name",
            width: "200px",
          },
          {
            headerName: $tt("Адреса"),
            field: "address",
            width: "200px",
          },
          {
            headerName: "AxiomaID",
            field: "axiomaId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("ЄДРПОУ"),
            field: "okpo",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Організація"),
            field: "orgName",
            width: "160px",
          },
          {
            headerName: $tt("План, од. вимірювання"),
            width: "100px",
            valueGetter: () => {
              return that.selectOptions.restricts.planMeasure.find((option) => option.value == that.$store.getters["restricts/restrict"]().planMeasure).label;
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("План, значення"),
            field: "plan",
            width: "100px",
            editable: true,
            type: "rightAligned",
            singleClickEdit: true,
            valueSetter: function(params) {
              if (digitalValueValidateRule(params.newValue)) {
                that
                  .restrictOrgsCommitChanges({
                    operation: "update",
                    model: _.extend(params.data, { plan: params.newValue, planFixed: isNumeric(params.newValue) && params.newValue >= 0 }),
                  })
                  .then(() => {
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      columns: [params.column],
                    });
                  })
                  .catch(() => {});
              }
              return false;
            },
          },
          {
            headerName: $tt("Фікс. план"),
            field: "planFixed",
            width: "100px",
            cellRenderer: "GridCellCheckbox",
            cellCheckbox: {
              onChange(params, value) {
                that.restrictOrgsCommitChanges({ operation: "update", model: _.extend(params.data, { planFixed: value }) });
              },
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Планограма"),
            field: "planogram",
            width: "100px",
            cellRenderer: "GridCellCheckbox",
            cellCheckbox: {
              onChange(params, value) {
                if (!params.node.isRowPinned()) that.restrictOrgsCommitChanges({ operation: "update", model: _.extend(params.data, { planogram: value }) });
              },
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Бонус, од. вимірювання"),
            field: "bonusMeasure",
            width: "100px",
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Умова"),
            field: "calcType",
            width: "200px",
          },
          {
            headerName: $tt("Тип умови"),
            field: "conditionType",
            width: "120px",
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 1,
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.status ? $tt("Активний") : "-";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Категорія"),
            field: "srcCategory",
            width: "50px",
            filter: "agSetColumnFilter",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        sku: [
          actionColumn,
          {
            headerName: "MorionID",
            field: "drugsId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Назва"),
            field: "sku",
            flex: 2,
          },
          {
            headerName: $tt("Виробник"),
            field: "maker",
            flex: 1,
          },
          {
            headerName: $tt("Маркетуюча організація"),
            field: "marketOrg",
            flex: 1,
          },
          {
            headerName: $tt("Бренд"),
            field: "brand",
            flex: 1,
          },
          {
            headerName: $tt("Рег. дата початок"),
            field: "regDateBegin",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("Рег. дата завершення"),
            field: "regDateEnd",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("CIP Ціна"),
            field: "cip",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("CIP Ціна, Грн"),
            field: "cipUAH",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Бонус"),
            field: "bonus",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Форма"),
            field: "drugForm",
            flex: 1,
          },
          {
            headerName: $tt("Номер"),
            field: "drugFormNumber",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Приорітет"),
            field: "priority",
            width: "100px",
            filter: "agSetColumnFilter",
            valueGetter: function(params) {
              switch (params.data.priority) {
                case "high":
                  return $tt("Високий");
                  break;
                case "low":
                  return $tt("Низький");
                  break;
                default:
                  return $tt("Нормальний");
                  break;
              }
            },
            comparator: function(valueA, valueB) {
              var vA = 0;
              var vB = 0;
              switch (valueA) {
                case $tt("Високий"):
                  vA = 3;
                  break;
                case $tt("Низький"):
                  vA = 1;
                  break;
                default:
                  vA = 2;
                  break;
              }
              switch (valueB) {
                case $tt("Високий"):
                  vB = 3;
                  break;
                case $tt("Низький"):
                  vB = 1;
                  break;
                default:
                  vB = 2;
                  break;
              }
              return vA > vB ? 1 : -1;
            },
            sort: "asc",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      },
    };
  },
  computed: {
    restrict() {
      return this.$store.getters["restricts/restrict"]();
    },
    columnsComp() {
      return this.columns[this.activeTab];
    },
    rulesComp() {
      return this.restrict.rules;
    },
    orgsComp() {
      return this.$store.getters["restricts/orgs"]();
    },
    orgsCompOKPO() {
      return this.$store.getters["restricts/orgsWithOKPO"]({
        byName: this.filterByName,
      });
    },
    orgsCompRoots() {
      return this.$store.getters["restricts/orgsRoots"]();
    },
    orgsChildComp() {
      if (this.selected.orgs && this.selected.orgs.length > 0) return this.$store.getters["restricts/orgsByParent"](this.selected.orgs[0]);
      else return [];
    },
    skuComp() {
      return this.$store.getters["restricts/restrictSKU"]({
        byName: this.filterByName,
      });
    },
    orgsTotalComp() {
      var result = [{ plan: 0 }];
      if (this.$store.getters["restricts/restrictPlanSettings"]().ruleId) {
        // For calc plan
        this.orgsCompRoots.forEach((org) => {
          if (org.plan && org.plan > 0) {
            result[0].plan = Number(result[0].plan) + Number(org.plan);
          }
        });
      } else {
        // For imported plan
        this.orgsComp.forEach((org) => {
          if (org.plan && org.plan > 0) {
            result[0].plan = Number(result[0].plan) + Number(org.plan);
          }
        });
      }
      return result;
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  watch: {
    activeTab(value) {
      this.$store.dispatch("main/updateUserSettings", { restrictDetailsActiveTab: value });
    },
    windowHeight(value) {
      var containerHeight = value - 48 - 72 - 42;
      // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.windowHeight = window.innerHeight;
        });
        this.resizeObserver.observe(document.body);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(document.body);
      this.resizeObserver = null;
      this.windowHeight = 0;
    },
    resetFilterName(event) {
      this.$refs.inputFilterName.clear();
    },
    editIconClick(item) {
      this.$message("!");
    },
    onGridDoubleClick() {
      this.editSelected(null);
    },
    onGridDoubleClickSKU(event) {
      this.editSelected(event, event.data);
    },
    onGridDoubleClickOrg(event) {
      if (!event.node.isRowPinned()) {
        event.api.stopEditing();
        this.editSelected(event, event.data);
      }
    },
    wait(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    onGridSelectionChangedRules() {
      var rows = this.gridOptions1.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.rules = rows;
      else this.selected.rules = null;
    },
    onGridSelectionChangedOrgs() {
      var rows = this.gridOptions2.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.orgs = rows;
      else this.selected.orgs = null;
      //this.$nextTick(() => this.gridOptions2Child.api.refreshCells());
    },
    onGridSelectionChangedOrgsChild() {
      var rows = this.gridOptions2Child.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.orgsChild = rows;
      else this.selected.orgsChild = null;
    },
    onGridSelectionChangedSKU() {
      var rows = this.gridOptions3.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.sku = rows;
      else this.selected.sku = null;
    },
    getRestrictRuleById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictRuleById"](id);
      } else return null;
    },
    getRestrictOrgById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictOrgById"](id);
      } else return null;
    },
    getRestrictSKUById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictSKUById"](id);
      } else return null;
    },
    onFindOrgsCommit(data) {
      if (data.result && data.result.length > 0) {
        var existIds = this.restrict.orgs.map((item) => item.axiomaId);
        var newOrgs = data.result.filter((item) => existIds.indexOf(item.axiomaId) == -1);
        var newData = this.restrict.orgs.concat(newOrgs);
        this.$store
          .dispatch("restricts/insertOrg", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            data: newData,
          })
          .then((data) => {
            if (newOrgs && newOrgs.length) this.$message.success($tt("Додано {count} структур!", { count: newOrgs.length }));
          })
          .catch(() => {
            globalErrorMessage($tt("Помилка збереження!"), err);
          });
      }
    },
    onFindOrgsWithPlanCommit(data) {
      if (data.result && data.result.length > 0) {
        var existIds = this.restrict.orgs.map((item) => item.axiomaId);
        var newOrgs = data.result.filter((item) => existIds.indexOf(item.axiomaId) == -1);
        var newData = this.restrict.orgs.concat(newOrgs);
        this.$store
          .dispatch("restricts/insertOrg", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            data: newData,
          })
          .then((data) => {
            if (newOrgs && newOrgs.length) this.$message.success($tt("Додано {count} структур!", { count: newOrgs.length }));
            this.$store.dispatch("contracts/updateStructures", { orgs: newData }).catch((err) => {
              globalErrorMessage("Помилка оновлення довідника структур!", err);
            });
          })
          .catch(() => {
            globalErrorMessage($tt("Помилка збереження!"), err);
          });
      }
    },
    onFindSKUCommit(data) {
      if (data.result && data.result.length > 0) {
        var existIds = this.restrict.sku.map((item) => item.drugsId);
        var newSKU = data.result.filter((item) => existIds.indexOf(item.drugsId) == -1);
        var newData = this.restrict.sku.concat(newSKU);
        this.$store
          .dispatch("restricts/insertSKU", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            data: newData,
          })
          .then(() => {
            this.$message.success("Товари додано!");
            this.$store.dispatch("contracts/updateSKUDictionary", { id: this.$store.getters["contracts/contract"]()._id, sku: newData }).catch((err) => {
              globalErrorMessage("Помилка оновлення довідника товарів!", err);
            });
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка збереження!"), err);
          });
      }
    },
    importOrgTreeWithPlan(data) {
      var that = this;
      if (data && data.length > 0) {
        //
        // Get Structures tree
        //
        this.$store.dispatch("main/setGlobalLoading", $tt("Завантаження структур..."));
        this.$store.dispatch("orgs/treeClear", []).then(() => {
          var count = 1;
          data
            .reduce((prev, org) => {
              return prev.then(() => {
                this.$store.dispatch("main/setGlobalLoading", `Завантаження структур...${count++} із ${data.length}`);
                return this.$store.dispatch("orgs/add_tree", { id: org.axiomaId }).catch((err) => {
                  globalErrorMessage($tt("Помилка оновлення!"), err);
                  this.$store.dispatch("main/setGlobalLoading");
                });
              });
            }, Promise.resolve())
            .then(() => {
              //
              // Append plan to tree
              //
              var newOrgs = that.$store.getters["orgs/tree"];
              newOrgs.forEach((org) => {
                var planForOrg = data.find((orgPlan) => orgPlan.axiomaId == org.axiomaId);
                if (planForOrg) {
                  org.plan = planForOrg.plan;
                  org.planFixed = true;
                }
              });
              //
              // Replace Orgs
              //
              that.$store.dispatch("main/setGlobalLoading", $tt("Збереження структур..."));
              that.$store
                .dispatch("restricts/replaceOrgs", {
                  contractId: that.$store.getters["contracts/contract"]()._id,
                  restrictId: that.$store.getters["restricts/restrict"]()._id,
                  newOrgs: newOrgs,
                })
                .then(() => {
                  if (newOrgs && newOrgs.length) this.$message.success($tt("Додано {count} структур!", { count: newOrgs.length }));
                  var newOrgsForDict = newOrgs.map((org) => {
                    var newOrg = _.extend({}, org);
                    delete newOrg.plan;
                    delete newOrg.planFixed;
                    return newOrg;
                  });
                  this.$store
                    .dispatch("contracts/updateStructures", { id: this.$store.getters["contracts/contract"]()._id, structures: newOrgsForDict })
                    .catch((err) => {
                      globalErrorMessage("Помилка оновлення довідника структур!", err);
                    });
                })
                .catch((err) => {
                  globalErrorMessage($tt("Помилка!"), err);
                })
                .finally(() => {
                  that.$store.dispatch("main/setGlobalLoading");
                });
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка!"), err);
            });
        });
      }
    },
    importOrgPlanUpdate(data) {
      var that = this;
      if (data && data.length > 0) {
        //
        // Update plan
        //
        that.orgsComp.forEach((org) => {
          var planForOrg = data.find((orgPlan) => orgPlan.axiomaId == org.axiomaId);
          if (planForOrg) {
            org.plan = planForOrg.plan;
            org.planFixed = true;
            var rowNode1 = that.gridOptions2.api.getRowNode(org.axiomaId);
            if (rowNode1) rowNode1.setData(org);
            else {
              var rowNode2 = that.gridOptions2Child.api.getRowNode(org.axiomaId);
              if (rowNode2) rowNode2.setData(org);
            }
          }
        });
        //
        // Update Orgs
        //
        that.$store.dispatch("main/setGlobalLoading", $tt("Збереження структур..."));
        that.$store
          .dispatch("restricts/replaceOrgs", {
            contractId: that.$store.getters["contracts/contract"]()._id,
            restrictId: that.$store.getters["restricts/restrict"]()._id,
            newOrgs: that.orgsComp,
          })
          .then(() => {
            that.$message.success("Плани додано!");
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка!"), err);
          })
          .finally(() => {
            that.$store.dispatch("main/setGlobalLoading");
          });
      }
    },
    importOrgTreeWithPlanAppend(data) {
      var that = this;
      var existIds = that.restrict.orgs.map((item) => item.axiomaId);
      var data = data.filter((org) => existIds.indexOf(org.axiomaId) == -1);
      if (data && data.length > 0) {
        //
        // Get Structures tree
        //
        this.$store.dispatch("main/setGlobalLoading", $tt("Завантаження структур..."));
        this.$store.dispatch("orgs/treeClear", []).then(() => {
          var count = 1;
          data
            .reduce((prev, org) => {
              return prev.then(() => {
                this.$store.dispatch("main/setGlobalLoading", `Завантаження структур...${count++} із ${data.length}`);
                return this.$store.dispatch("orgs/add_tree", { id: org.axiomaId }).catch((err) => {
                  globalErrorMessage($tt("Помилка оновлення!"), err);
                  this.$store.dispatch("main/setGlobalLoading");
                });
              });
            }, Promise.resolve())
            .then(() => {
              //
              // Append plan to tree
              //
              var newOrgs = that.$store.getters["orgs/tree"];
              newOrgs.forEach((org) => {
                var planForOrg = data.find((orgPlan) => orgPlan.axiomaId == org.axiomaId);
                if (planForOrg) {
                  org.plan = planForOrg.plan;
                  org.planFixed = true;
                }
              });
              //
              // Append Orgs
              //
              that.$store.dispatch("main/setGlobalLoading", $tt("Збереження структур..."));
              var newOrgs = newOrgs.filter((item) => existIds.indexOf(item.axiomaId) == -1);
              var newData = that.restrict.orgs.concat(newOrgs);
              that.$store
                .dispatch("restricts/insertOrg", {
                  contractId: that.$store.getters["contracts/contract"]()._id,
                  restrictId: that.$store.getters["restricts/restrict"]()._id,
                  data: newData,
                })
                .then((data) => {
                  if (newOrgs && newOrgs.length) this.$message.success($tt("Додано {count} структур!", { count: newOrgs.length }));
                  var newOrgsForDict = newOrgs.map((org) => {
                    var newOrg = _.extend({}, org);
                    delete newOrg.plan;
                    delete newOrg.planFixed;
                    delete newOrg.planogram;
                    return newOrg;
                  });
                  this.$store
                    .dispatch("contracts/updateStructures", { id: this.$store.getters["contracts/contract"]()._id, structures: newOrgsForDict })
                    .catch((err) => {
                      globalErrorMessage("Помилка оновлення довідника структур!", err);
                    });
                })
                .catch(() => {
                  globalErrorMessage($tt("Помилка збереження!"), err);
                });
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка!"), err);
            })
            .finally(() => {
              that.$store.dispatch("main/setGlobalLoading");
            });
        });
      }
    },
    createNew() {
      switch (this.activeTab) {
        case "rules": {
          bus.$emit("forms.rules.edit.show", {
            title: $tt("Створення правила план/бонус"),
            operation: "create",
            initModel: { applyCondition: "and", parentRules: [] },
          });
          break;
        }
        case "orgs": {
          bus.$emit("forms.orgsInTree.find.show", {
            title: $tt("Пошук структур"),
            singleRecord: false,
            target: "executor",
          });
          break;
        }
        case "sku": {
          bus.$emit("forms.sku.dictionary.show", {
            title: $tt("Довідник товарів"),
            selectedSKU: this.$store.getters["contracts/contract"]().sku,
            target: "restrict",
          });
          break;
        }
      }
    },

    editSelected(event, data) {
      switch (this.activeTab) {
        case "rules": {
          bus.$emit("forms.rules.edit.show", {
            title: $tt("Редагування правила план/бонус"),
            operation: "update",
            initModel: data ? this.getRestrictRuleById(data._id) : this.getRestrictRuleById(this.selected.rules[0]._id),
          });

          break;
        }
        case "orgs": {
          bus.$emit("forms.rules.org.edit.show", {
            title: $tt("Редагування структури"),
            operation: "update",
            initModel: data ? this.getRestrictOrgById(data.axiomaId) : this.selected.orgsChild ? this.selected.orgsChild[0] : this.selected.orgs[0],
          });
          break;
        }
        case "sku": {
          bus.$emit("forms.rules.sku.edit.show", {
            title: $tt("Редагування товара"),
            operation: "update",
            initModel: data ? this.getRestrictSKUById(data.drugsId) : this.getRestrictSKUById(this.selected.sku[0].drugsId),
          });

          break;
        }
      }
    },

    deleteSelected(event, data) {
      var that = this;
      switch (this.activeTab) {
        case "rules": {
          if (this.selected.rules || data) {
            this.$confirm($tt("Ви дійсно бажаєте видалити обрані правила?"), $tt("Увага"), {
              confirmButtonText: $tt("Так"),
              cancelButtonText: $tt("Ні"),
              type: "warning",
            })
              .then(() => {
                var idForDelete = data && data._id ? [data._id] : this.selected.rules.map((item) => item._id);
                this.$store
                  .dispatch("restricts/deleteRule", {
                    contractId: this.$store.getters["contracts/contract"]()._id,
                    restrictId: this.$store.getters["restricts/restrict"]()._id,
                    data: idForDelete,
                  })
                  .then(() => {
                    this.$message.success($tt("Правила видалено!"));
                    this.gridOptions1.api.refreshCells();
                  })
                  .catch((err) => {
                    globalErrorMessage($tt("Помилка видалення!"), err);
                  });
              })
              .catch((err) => {});
          }
          break;
        }
        case "orgs": {
          var delGrid = 0;
          if (data) {
            var delIds = [this.getRestrictOrgById(data.axiomaId)].map((item) => item.axiomaId);
          } else if (this.selected.orgsChild && this.selected.orgsChild.length > 0) {
            var delIds = this.selected.orgsChild.map((item) => item.axiomaId);
            delGrid = 2;
          } else if (this.selected.orgs && this.selected.orgs.length > 0) {
            var delIds = this.selected.orgs.map((item) => item.axiomaId);
            delGrid = 1;
          } else return;
          var additionalDelIds = [];
          this.orgsComp.map((item) => {
            if (delIds.indexOf(item.axiomaId) != -1) return false;
            var intersect = item.path.filter((value) => delIds.includes(value[0]));
            if (intersect && intersect.length > 0) {
              additionalDelIds.push(item.axiomaId);
              return false;
            }
            return true;
          });
          var idsForDel = delIds.concat(additionalDelIds);
          this.$confirm($tt("Для видалення обрано {count} структур. Видалити обрані структури?", { count: idsForDel.length }), $tt("Увага"), {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          })
            .then(() => {
              this.$store
                .dispatch("restricts/deleteOrg", {
                  contractId: this.$store.getters["contracts/contract"]()._id,
                  restrictId: this.$store.getters["restricts/restrict"]()._id,
                  data: idsForDel,
                })
                .then(() => {
                  this.$message.success($tt("Структури видалено!"));
                  if (delGrid == 1) {
                    this.selected.orgs = null;
                    this.gridOptions2.api.refreshCells();
                  }
                  if (delGrid == 2) {
                    this.selected.orgsChild = null;
                    this.gridOptions2Child.api.refreshCells();
                  }
                })
                .catch((err) => {
                  globalErrorMessage($tt("Помилка видалення!"), err);
                });
            })
            .catch((err) => {});
          break;
        }
        case "sku":
          {
            if (this.selected.sku || data) {
              this.$confirm($tt("Ви дійсно бажаєте видалити обрані товари?"), $tt("Увага"), {
                confirmButtonText: $tt("Так"),
                cancelButtonText: $tt("Ні"),
                type: "warning",
              })
                .then(() => {
                  var idForDelete = data && data.drugsId ? [data.drugsId] : that.selected.sku.map((item) => item.drugsId);
                  that.$store
                    .dispatch("restricts/deleteSKU", {
                      contractId: that.$store.getters["contracts/contract"]()._id,
                      restrictId: that.$store.getters["restricts/restrict"]()._id,
                      data: idForDelete,
                    })
                    .then(() => {
                      that.$message.success($tt("Товари видалено!"));
                      that.selected.sku = null;
                      that.gridOptions3.api.refreshCells();
                    })
                    .catch((err) => {
                      globalErrorMessage($tt("Помилка видалення!"), err);
                    });
                })
                .catch((err) => {});
            }
          }
          break;
      }
    },

    showInfo(event, data) {
      var that = this;
      switch (this.activeTab) {
        case "rules": {
          break;
        }
        case "orgs": {
          break;
        }
        case "sku": {
          bus.$emit("restricts.details.sku.forms.info.show", {
            title: $tt("Інформація про товар"),
            initModel: data ? { drugsId: data.drugsId } : { drugsId: this.selected.sku[0].drugsId },
          });
          break;
        }
      }
    },

    restrictRuleCommitChanges(data) {
      if (data.operation == "create") {
        var loading = this.$loading({ target: "#EditRestrictRuleDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/insertRule", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            data: data.model,
          })
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.rules.edit.hide");
            this.$message.success($tt("Правило додано!"));
            var node = this.gridOptions1.api.getRowNode(newData.data._id);
            this.gridOptions1.api.onSortChanged();
            if (node) node.setSelected(true);
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка створення!"), err);
          });
      } else if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditRestrictRuleDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/updateRule", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            ruleId: data.model._id,
            data: data.model,
          })
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.rules.edit.hide");
            var rowNode = this.gridOptions1.api.getRowNode(data.model._id);
            rowNode.setData(data.model);
            this.gridOptions1.api.onSortChanged();
            this.$message.success($tt("Правило оновлено!"));
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка збереження!"), err);
          });
      }
    },
    restrictOrgsCommitChanges(data, noRecalc) {
      var that = this;
      return new Promise((resolve, reject) => {
        if (data.operation == "update") {
          var loading = this.$loading({ target: "#EditRestrictOrgDialog>div", text: $tt("Будь ласка, зачекайте...") });
          this.$store
            .dispatch("restricts/updateOrg", {
              contractId: this.$store.getters["contracts/contract"]()._id,
              restrictId: this.$store.getters["restricts/restrict"]()._id,
              axiomaId: data.model.axiomaId,
              data: data.model,
            })
            .finally(() => {
              loading && loading.close();
            })
            .then(() => {
              bus.$emit("forms.rules.org.edit.hide");
              var rowNode1 = that.gridOptions2.api.getRowNode(data.model.axiomaId);
              if (rowNode1) rowNode1.setData(data.model);
              var rowNode2 = that.gridOptions2Child.api.getRowNode(data.model.axiomaId);
              if (rowNode2) rowNode2.setData(data.model);
              this.gridOptions2.api.onSortChanged();
              this.gridOptions2Child.api.onSortChanged();
              resolve();
              if (this.$store.getters["restricts/restrictPlanSettings"]().saveAsDefault && !noRecalc) {
                this.calcPlan();
              }
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка збереження!"), err);
              reject(err);
            });
        }
      });
    },
    restrictSKUCommitChanges(data) {
      if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditRestrictSKUDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/updateSKU", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            drugsId: data.model.drugsId,
            data: data.model,
          })
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.rules.sku.edit.hide");
            var rowNode = this.gridOptions3.api.getRowNode(data.model.drugsId);
            rowNode.setData(data.model);
            this.gridOptions3.api.onSortChanged();
            this.$message.success($tt("Товар оновлено!"));
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка збереження!"), err);
          });
      }
    },
    restrictPlanCommitChanges(data) {
      var loading = this.$loading({ target: "#EditRestrictPlanSettingsDialog>div", text: $tt("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("restricts/updatePlanSettings", {
          contractId: this.$store.getters["contracts/contract"]()._id,
          restrictId: this.$store.getters["restricts/restrict"]()._id,
          data: data.model,
        })
        .finally(() => {
          loading && loading.close();
        })
        .then(() => {
          bus.$emit("forms.rules.plan.edit.hide");
          this.$message.success($tt("Налаштування планування збережено!"));
          if (this.$store.getters["restricts/restrictPlanSettings"]().saveAsDefault) {
            this.calcPlan();
          }
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка збереження!"), err);
        });
    },
    importSKUFromXLS() {
      bus.$emit("forms.sku.import.from.xlsx.file.show", {
        title: $tt("Завантаження списку товарів"),
        operation: "sku.import.xls",
        initModel: { skuExists: this.selectedSKU },
      });
    },
    importSKUFromJSON() {
      bus.$emit("forms.sku.import.from.json.file.show", {
        title: $tt("Імпорт товарів"),
        operation: "sku.import",
        initModel: {},
      });
    },
    importOrgWithPlanFromXLS() {
      bus.$emit("forms.org.w.plan.import.from.xlsx.file.show", {
        title: $tt("Імпорт структур"),
        operation: "org.w.plan.import",
        initModel: { orgExists: this.orgsComp },
      });
    },
    importSKUFromJSONData(data) {
      var that = this;
      bus.$emit("forms.sku.import.from.json.file.clear");
      that
        .readJSONFile(data.file)
        .then((result) => {
          if (result && result.dataType && result.dataType == "skuArray" && result.data) {
            that.onFindSKUCommit({ result: result.data.filter((item) => item.dataType == "sku").map((item) => item.data) });
          } else if (result && result.dataType && result.dataType == "sku" && result.data) {
            that.onFindSKUCommit({ result: [result.data] });
          } else {
            globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
          }
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка імпорту"), err);
        });
    },
    importSKUFromXLSXData(result) {
      this.onFindSKUCommit({ result: result.data });
    },
    importSKUFromGroups() {
      bus.$emit("forms.sku.import.from.groups.show", {
        title: $tt("Імпорт товарів із конкурентних груп"),
      });
    },
    importOrgWithPlansFromXLSXData(result) {
      switch (result.importPlanPolicy) {
        case "clear":
          this.importOrgTreeWithPlan(result.data);
          break;
        case "updateExists":
          this.importOrgPlanUpdate(result.data);
          break;
        case "insertNew":
          this.importOrgTreeWithPlanAppend(result.data);
          break;
      }
    },
    exportRulesToJSON(rule) {
      if (this.selected.rules) {
        var dt = new Date()
          .toLocaleString()
          .split(",")
          .join("-");
        if (this.selected.rules.length > 1) {
          var result = this.selected.rules.map((rule) => {
            return { dataType: "rule", data: rule };
          });
          this.saveDataToJSON({ dataType: "rulesArray", data: result }, "rules_" + dt + ".json");
        } else {
          this.saveDataToJSON({ dataType: "rule", data: this.selected.rules[0] }, "rule_" + this.selected.rules[0]._id + "_" + dt + ".json");
        }
      }
    },
    exportSKUToJSON(rule) {
      if (this.selected.sku) {
        var dt = new Date()
          .toLocaleString()
          .split(",")
          .join("-");
        if (this.selected.sku.length > 1) {
          var result = this.selected.sku.map((sku) => {
            return { dataType: "sku", data: sku };
          });
          this.saveDataToJSON({ dataType: "skuArray", data: result }, "skuArray_" + dt + ".json");
        } else {
          this.saveDataToJSON({ dataType: "sku", data: this.selected.sku[0] }, "sku_" + this.selected.sku[0].drugsId + "_" + dt + ".json");
        }
      }
    },
    exportOrgsToJSON(rule) {
      if (this.selected.orgs || this.selected.orgsChild) {
        var dt = new Date()
          .toLocaleString()
          .split(",")
          .join("-");
        var summarySelected = (this.selected.orgs ? this.selected.orgs.length : 0) + (this.selected.orgsChild ? this.selected.orgsChild.length : 0);
        if (summarySelected > 1) {
          var result = this.selected.orgs.concat(this.selected.orgsChild).map((org) => {
            return { dataType: "org", data: org };
          });
          this.saveDataToJSON({ dataType: "orgsArray", data: result }, "orgsArray_" + dt + ".json");
        } else {
          if (this.selected.orgs.length)
            this.saveDataToJSON({ dataType: "org", data: this.selected.orgs[0] }, "org_" + this.selected.orgs[0].axiomaId + "_" + dt + ".json");
          else if (this.selected.orgsChild.length)
            this.saveDataToJSON({ dataType: "org", data: this.selected.orgs[0] }, "org_" + this.selected.orgsChild[0].axiomaId + "_" + dt + ".json");
        }
      }
    },
    updateSKUActiveStatus() {
      //this.a;
    },
    handleClick(command) {
      switch (command) {
        case "export-to-json": {
          switch (this.activeTab) {
            case "rules": {
              this.exportRulesToJSON();
              break;
            }
            case "orgs": {
              this.exportOrgsToJSON();
              break;
            }
            case "sku": {
              this.exportSKUToJSON();
              break;
            }
          }
          break;
        }
        case "import-from-groups": {
          this.importSKUFromGroups();
          break;
        }
        case "import-from-json": {
          this.importSKUFromJSON();
          break;
        }
        case "import-from-xlsx": {
          this.importSKUFromXLS();
          break;
        }
        case "import-org-w-plan-from-xlsx": {
          this.importOrgWithPlanFromXLS();
          break;
        }

        case "calc-plan": {
          this.calcPlan();
          break;
        }
        case "clear-plan": {
          this.clearPlan();
          break;
        }
        case "calc-plan-settings": {
          this.editPlanSettings();
          break;
        }
        default:
          break;
      }
    },
    onGridColumnsChange1() {
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer1);
      this.changeColumnsTimer = setTimeout(() => {
        var state = this.gridOptions1.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimer);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsRulesGridColumnState: state });
      }, 500);
    },
    onGridColumnsChange2() {
      if (this.changeColumnsTimer2) clearTimeout(this.changeColumnsTimer2);
      this.changeColumnsTimer2 = setTimeout(() => {
        var state = this.gridOptions2.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimer2);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsOrgsGridColumnState: state });
      }, 500);
    },
    onGridColumnsChange2Child() {
      if (this.changeColumnsTimer2Child) clearTimeout(this.changeColumnsTimer2Child);
      this.changeColumnsTimer2Child = setTimeout(() => {
        var state = this.gridOptions2Child.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimer2Child);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsOrgsChildGridColumnState: state });
      }, 500);
    },
    onGridColumnsChange3() {
      if (this.changeColumnsTimer3) clearTimeout(this.changeColumnsTimer3);
      this.changeColumnsTimer3 = setTimeout(() => {
        var state = this.gridOptions3.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimer3);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSKUGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    onGrid2CellValueChanged(event) {
      //this.$message("Data:" + event.data);
      //return false;
    },
    editPlanSettings(callback) {
      // Prepare settings for calc plan
      var restrict = this.$store.getters["restricts/restrict"]();
      bus.$emit("forms.rules.plan.edit.show", {
        title: $tt("Налаштування розподілу плану"),
        initModel: {
          rules: restrict.rules,
          planSettings:
            restrict.planSettings && Object.keys(restrict.planSettings).length > 0
              ? _.clone(restrict.planSettings)
              : {
                  planGRPercent: 0,
                  planAlgType: "shareByTurnover",
                  turnoverPeriodType: "last3Months",
                  beginDate: restrict.beginDate,
                  endDate: restrict.endDate,
                  ruleId: restrict.rules && restrict.rules.length == 1 ? restrict.rules[0]._id : undefined,
                },
        },
      });
    },
    calcPlan() {
      this.restrictDetailsLoading = true;
      var restrict = this.$store.getters["restricts/restrict"]();
      if (restrict && restrict.planSettings && restrict.planSettings.ruleId) {
        this.$store
          .dispatch("restricts/calcPlan")
          .then((data) => {
            this.gridOptions2.api.refreshCells();
            this.gridOptions2Child.api.refreshCells();
            this.$message.success(data);
          })
          .catch((msg) => {
            this.$message.error($tt("Помилка: {msg}", { msg }));
          });
      } else {
        this.editPlanSettings(this.calcPlan);
      }
    },
    clearPlan() {
      this.$confirm($tt("Ви дійсно бажаєте зкинути план?"), $tt("Увага"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Ні"),
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("restricts/clearPlan")
            .then((msg) => {
              this.gridOptions2.api.refreshCells();
              this.gridOptions2Child.api.refreshCells();
              this.$message.success(msg);
            })
            .catch((err) => {
              globalErrorMessage(err);
            });
        })
        .catch((err) => {
          globalErrorMessage(err);
        });
    },
  },
};
export default Restricts;
</script>

<style lang="scss" scope>
.el-main {
  padding: 0;
  background: #fff;
  padding: 0px !important;
  height: calc(100vh - 164px);
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 136px;
}

.invalid {
  background: rgb(253, 171, 171);
  transition: all 1s;
}

.tab-bar {
  font-size: 17px;
  .el-tabs__item {
    width: 272px;
    color: gray;
  }
  .el-tabs__item.is-active {
    color: #26ae4d;
    font-weight: 700;
  }
  .el-tabs__active-bar {
    width: 272px;
    background-color: #26ae4d;
  }
}
</style>

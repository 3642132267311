import Vue from "vue";

export default Vue.extend({
  template: `<div><el-checkbox v-model="checked" @change="onChange" @click v-if="visible"></el-checkbox></div>`,
  data: function() {
    return {};
  },
  methods: {
    onChange(value) {
      if (this.params.colDef.cellCheckbox.onChange) this.params.colDef.cellCheckbox.onChange(this.params, value);
    },
  },
  computed: {
    checked: {
      get() {
        return this.params.value;
      },
      set(value) {
        //this.params.value = value;
      },
    },
    visible() {
      return !this.params.node.isRowPinned();
    },
  },
});

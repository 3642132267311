<template>
  <div class="header-button-separator"></div>
</template>
<script>
export default {
  name: "ToolbarSeparator",
};
</script>
<style scoped>
.header-button-separator {
  width: 1px;
  background: #d4d8ce;
  height: 24px;
}
</style>
